import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import BannerOne from '../BannerOne';
import { mobile, smMobile, tablet, useQuery } from 'styles/breakpoints';
import PaymentSteps from 'pages/start/payments/components/PaymentSteps';
import Button from '../Button';
import CancelButton from '../CancelButton';
import { DynamicImage, Loader } from 'components';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import quizSvgs from 'utils/constants/quizSvgs';
import { Horoscope, Origin } from 'circular-natal-horoscope-js';
import useQuizAnswersBirthDetails from 'utils/hooks/useQuizAnswersBirthDetails';
import BirthChart from 'pages/start/quiz/components/BirthChart';
import CircleType from 'circletype';
import { resolveBookCoverName } from 'utils/hooks/resolveBookCoverName';

interface ExpressShippingProps {}

const PaymentStepsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem;
  }
`;

const PaymentStepsStyled = styled(PaymentSteps)`
  width: 100%;
`;
const CancelButtonStyled = styled(CancelButton)`
  margin: 0.75rem 0 0 0;
`;

const Title3 = styled.p`
  color: #000;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
  margin-bottom: 0.75rem;
  text-align: center;
  @media ${tablet} {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
  }
`;

const Label3 = styled.p`
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 48.275rem;
  width: 100%;
  text-align: center;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    margin-bottom: 0.75rem;
    padding: 0;
  }
`;

const TopTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0 0;
  @media ${tablet} {
    padding: 0 1rem;
  }
`;

const Headline = styled.p`
  color: #1c1c28;
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.25rem;
  margin-bottom: 1rem;
  max-width: 48.375rem;
  @media ${tablet} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.p`
  color: #28293d;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3.2rem;
  text-align: center;
  max-width: 30.5rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
    margin-bottom: 2.25rem;
  }
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 18.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BookContainerBottom = styled(BookContainer)`
  padding: 2rem 0;
`;
const BookCover = styled(DynamicImage)`
  width: 11.1rem;
  height: 14.4rem;
  z-index: 2;
`;

const ImageStyled = styled(DynamicImage)`
  z-index: -1;
  position: absolute;
  top: -0.04494rem;
  width: 16.8167rem;
  height: 15.5rem;
  left: -41px;
`;

const ImageStyledBottom = styled(ImageStyled)`
  z-index: 1;
  width: 19rem;
  height: 23rem;
  left: 45px;
  top: 34px;

  @media ${mobile} {
    width: 12.5rem;
    height: 12.2rem;
    right: 30px;
    left: unset;
    top: 24px;
  }
`;

const BottomContainer = styled.div`
  background: #f3f3f8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 1rem;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 0.5rem;
    padding: 2rem 1rem 1.5rem;
  }
`;

const EbookContainer = styled.div`
  display: flex;
  padding: 0.1295rem 0.51794rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  border-radius: 0.27181rem;
  background: #f7c800;
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: #000;
  text-align: center;
  font-size: 1.071rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BookImageContainer = styled.div`
  position: relative;
  div#paper {
    position: absolute !important;
    top: 64px;
    right: 50%;
    transform: translateX(50%);
  }
`;

const BookImageContainerBottom = styled.div`
  position: relative;
  z-index: 2;
  div#paper2 {
    position: absolute !important;
    top: 96px;
    right: 50%;
    transform: translateX(50%);
  }
  @media ${tablet} {
    div#paper2 {
      top: 48px;
    }
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  width: 11.5625rem;
  background-size: contain;
`;

const StyledDynamicImageBottom = styled(DynamicImage)`
  width: 17.625rem;
  background-size: contain;
  @media ${tablet} {
    width: 8.8125rem;
  }
`;

const BirthChartStyled = styled(BirthChart)`
  position: absolute !important;
  top: 0;
  div#paper {
    position: absolute !important;
    top: 0;
  }
`;

const SunSign = styled.div<{ color: string }>`
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 59px;
  border: 0.532px solid ${({ color }) => color};
  position: absolute;
  bottom: 10px;
  left: 12px;
  gap: 1px;
`;

const MoonSign = styled(SunSign)`
  bottom: 10px;
  right: 10px;
  left: unset;
`;
const SunText = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 3.5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 15px;
  position: relative;
  text-transform: uppercase;
`;

const SvgSign = styled.div`
  width: 7px;
  height: 7px;
  position: relative;
  svg {
    position: absolute;
  }
`;

const SunSignBottom = styled.div<{ color: string }>`
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 59px;
  border: 1px solid ${({ color }) => color};
  position: absolute;
  bottom: 15px;
  left: 18px;
  gap: 2px;
  @media ${tablet} {
    width: 20px;
    height: 20px;
    bottom: 7px;
    left: 9px;
    gap: 0;
  }
`;

const MoonSignBottom = styled(SunSignBottom)`
  bottom: 15px;
  right: 19px;
  left: unset;
  @media ${tablet} {
    bottom: 7px;
    right: 7px;
  }
`;
const SunTextBottom = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-size: 5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-family: 'Montserrat', sans-serif;
  max-width: 18px;
  position: relative;
  text-transform: uppercase;
  @media ${tablet} {
    font-size: 2.5px;
    max-width: 12px;
  }
`;

const SvgSignBottom = styled.div`
  width: 13px;
  height: 13px;
  position: relative;
  svg {
    position: absolute;
  }
  @media ${tablet} {
    width: 6px;
    height: 6px;
  }
`;

const Name = styled.p<{
  color: string;
  isSurname: boolean;
  isNameLongerThan10: boolean;
  isSurameLongerThan10: boolean;
}>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  text-align: center;
  font-family: 'Arno Pro';
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '9px' : '12px'};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '10px' : '11px'};
  letter-spacing: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '1.5px' : '2.183px'};
  text-transform: uppercase;
  position: absolute;
  top: ${({ isSurname }) => (isSurname ? '2.6rem' : '3rem')};
  right: 50%;
  transform: translateX(50%);
  text-wrap: nowrap;
`;

const NameBottom = styled(Name)`
  font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '16px' : '18px'};
  ${({ isSurname }) => !isSurname && 'top: 4.5rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname && (isNameLongerThan10 || isSurameLongerThan10) && 'top: 4rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
    isSurname &&
    !(isNameLongerThan10 || isSurameLongerThan10) &&
    'top: 3.8rem;'}
  line-height: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
    isNameLongerThan10 || isSurameLongerThan10 ? '16px' : '16px'};
  @media ${tablet} {
    font-size: ${({ isNameLongerThan10, isSurameLongerThan10 }) =>
      isNameLongerThan10 || isSurameLongerThan10 ? '6.5px' : '8px'};
    line-height: 8px;
    ${({ isSurname }) => !isSurname && 'top: 2.2rem;'}
    ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
      isSurname &&
      (isNameLongerThan10 || isSurameLongerThan10) &&
      'top: 2.1rem;'}
  ${({ isSurname, isNameLongerThan10, isSurameLongerThan10 }) =>
      isSurname &&
      !(isNameLongerThan10 || isSurameLongerThan10) &&
      'top: 2rem;'}
   
    letter-spacing: 1.5px;
  }
`;

const Date = styled.p<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  font-family: 'Montserrat';
  font-size: 5px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px; /* Reduced letter-spacing */
  position: absolute;
  bottom: 2.2rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-transform: capitalize;
`;

const City = styled(Date)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 1.6rem;
`;

const DateBottom = styled(Date)`
  font-size: 7px;
  letter-spacing: 0.8px; /* Reduced letter-spacing */
  bottom: 3.4rem;
  @media ${tablet} {
    font-size: 3px;
    letter-spacing: 0.2px;
    bottom: 1.8rem;
  }
`;

const CityBottom = styled(DateBottom)<{ color: string }>`
  color: ${({ color }) => (color === 'beige' ? '#734F3E' : '#fff')};
  bottom: 2.6rem;
  @media ${tablet} {
    bottom: 1.4rem;
  }
`;

const ExpressShipping: FC<ExpressShippingProps> = ({
  bannerOne,
  paymentSteps,
  headline,
  label,
  PlanSectionComponent,
  planSection,
  headline2,
  label2,
  imageSection,
  headline3,
  label3,
  button,
  cancelButton,
  userHasBoughtPaperbackBook,
  loading,
  handleUpgradeClick,
  localisedProduct,
  handleCancelClick,
}) => {
  const { quiz_answers, code } = useSelector((state: AppState) => state.user);
  const color = quiz_answers?.color;
  const contentColor = quiz_answers?.contentColor;
  const { isTablet } = useQuery();
  const birthDetails = useQuizAnswersBirthDetails();

  const [showName, setShowName] = useState<boolean>(false);

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return <Svg />;
  };

  const textRefPlace = useRef<HTMLDivElement>(null);
  const textRefDate = useRef<HTMLDivElement>(null);
  const textRefPlace2 = useRef<HTMLDivElement>(null);
  const textRefDate2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRefPlace.current) {
      new CircleType(textRefPlace.current).dir(-1).radius(100);
    }
    if (textRefDate.current) {
      new CircleType(textRefDate.current).dir(-1).radius(90);
    }
    if (textRefPlace2.current) {
      new CircleType(textRefPlace2.current).dir(-1).radius(100);
    }
    if (textRefDate2.current) {
      new CircleType(textRefDate2.current).dir(-1).radius(90);
    }
  });
  const handleOnLoad = () => {
    setShowName(true);
  };

  const { isNameLongerThan10, isSurameLongerThan10, isSurname, formattedName } =
    resolveBookCoverName(quiz_answers?.name, quiz_answers?.surname);
  return (
    <>
      <BannerOne {...bannerOne} boughtPaperback={userHasBoughtPaperbackBook} />
      <PaymentStepsContainer>
        <PaymentStepsStyled
          {...paymentSteps}
          hasOptionalStep={userHasBoughtPaperbackBook}
        />
      </PaymentStepsContainer>
      <TopTextContainer>
        <Headline>{headline}</Headline>
        <Label>{label}</Label>
      </TopTextContainer>
      <BookContainer>
        <BookImageContainer>
          <ImageStyled src="upsell/background.png" alt="" />
          <StyledDynamicImage
            src={`booksCovers/${color}.png`}
            alt=""
            // handleOnLoad={handleOnLoad}
          />
          <BirthChartStyled
            year={birthDetails?.year as number}
            month={birthDetails?.month as number}
            day={birthDetails?.day as number}
            hour={birthDetails?.hours as number}
            minute={birthDetails?.minutes as number}
            latitude={quiz_answers?.birth_place.lat}
            longitude={quiz_answers?.birth_place.lng}
            color={contentColor}
            width={130}
            height={130}
          />
          <EbookContainer>ebook</EbookContainer>
          <Name
            color={color}
            dangerouslySetInnerHTML={{ __html: formattedName }}
            isSurname={isSurname}
            isNameLongerThan10={isNameLongerThan10}
            isSurameLongerThan10={isSurameLongerThan10}
          />
          <Date color={color} ref={textRefDate}>
            {birthDetails && birthDetails.fullDate}
          </Date>

          <City color={color} ref={textRefPlace}>
            {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
          </City>
          <SunSign color={contentColor}>
            <SunText color={color}>SUN SIGN</SunText>
            <SvgSign>
              {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
            </SvgSign>
          </SunSign>
          <MoonSign color={contentColor}>
            <SunText color={color}>MOON SIGN</SunText>
            <SvgSign>
              {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
            </SvgSign>
          </MoonSign>
        </BookImageContainer>
      </BookContainer>
      <PlanSectionComponent {...planSection} />
      <BottomContainer>
        <Title3>{headline3}</Title3>
        <Label3 dangerouslySetInnerHTML={{ __html: label3 }} />

        <BookContainerBottom>
          <BookImageContainerBottom>
            <StyledDynamicImageBottom
              src={`booksCovers/${color}.png`}
              alt=""
              // handleOnLoad={handleOnLoad}
            />
            <BirthChartStyled
              year={birthDetails?.year as number}
              month={birthDetails?.month as number}
              day={birthDetails?.day as number}
              hour={birthDetails?.hours as number}
              minute={birthDetails?.minutes as number}
              latitude={quiz_answers?.birth_place.lat}
              longitude={quiz_answers?.birth_place.lng}
              color={contentColor}
              width={isTablet ? 100 : 195}
              height={isTablet ? 100 : 195}
              divId="paper2"
            />
            <NameBottom
              color={color}
              dangerouslySetInnerHTML={{ __html: formattedName }}
              isSurname={isSurname}
              isNameLongerThan10={isNameLongerThan10}
              isSurameLongerThan10={isSurameLongerThan10}
            />
            <DateBottom color={color} ref={textRefDate2}>
              {birthDetails && birthDetails.fullDate}
            </DateBottom>

            <CityBottom color={color} ref={textRefPlace2}>
              {`${quiz_answers?.birth_place.main_text}, ${quiz_answers?.birth_place.secondary_text}`}
            </CityBottom>
            <SunSignBottom color={contentColor}>
              <SunTextBottom color={color}>SUN SIGN</SunTextBottom>
              <SvgSignBottom>
                {renderSvgImage(`${color}${quiz_answers?.zodiacSign}`)}
              </SvgSignBottom>
            </SunSignBottom>
            <MoonSignBottom color={contentColor}>
              <SunTextBottom color={color}>MOON SIGN</SunTextBottom>
              <SvgSignBottom>
                {renderSvgImage(`${color}${quiz_answers?.planets.moon}`)}
              </SvgSignBottom>
            </MoonSignBottom>
          </BookImageContainerBottom>
          <ImageStyledBottom
            src="upsell/express-shipping/mockup-page.png"
            alt=""
          />
        </BookContainerBottom>
        {loading ? (
          <Loader wrapperHeight={'1'} />
        ) : (
          <Button
            bgColor="primary"
            disabled={loading}
            onClick={handleUpgradeClick}
            {...button}
            secondaryText={`${button.secondaryText} ${localisedProduct?.currency}${localisedProduct?.finalPrice}`}
          >
            {button.text}
          </Button>
        )}
        <CancelButtonStyled
          disabled={loading}
          onCancelClick={handleCancelClick}
          noButtonTitle={cancelButton.noButtonTitle}
        />
      </BottomContainer>
    </>
  );
};

export default ExpressShipping;
